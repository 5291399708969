import { Component } from "react";


const title = "Meet The Team";
const desc = "Entering The Herox Society means joining an family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";


let teamList = [
    {
        imgUrl: 'assets/images/team/01.jpg',
        imgAlt: 'team Image',
        name: 'Somrat Islam',
        degi: 'UI/UX Designer',
        className: 'team__item',
    },
    {
        imgUrl: 'assets/images/team/02.jpg',
        imgAlt: 'team Image',
        name: 'Rassel Hossain',
        degi: 'Front End Designer',
        className: 'team__item team__item--bg-2',
    },
    {
        imgUrl: 'assets/images/team/03.jpg',
        imgAlt: 'team Image',
        name: 'Shajahan Sagor',
        degi: 'UI/UX Designer',
        className: 'team__item team__item--bg-3',
    },
    {
        imgUrl: 'assets/images/team/04.jpg',
        imgAlt: 'team Image',
        name: 'Rajib Ahmed',
        degi: 'Web Designer',
        className: 'team__item team__item--bg-4',
    },
]

class Team extends Component {
    render() { 
        return (
            <section className="team padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/team/bg.jpg)"}} id="team">
                <div className="container">
                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="team__wrapper">
                        <div className="row g-4">
                            {teamList.map((val, i) => (
                                <div className="col-lg-3 col-sm-6" key={i}>
                                    <div className={val.className} data-aos="fade-up" data-aos-duration="1000">
                                        <div className="team__inner">
                                            <div className="team__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="team__content">
                                                <h4><a href="#">{val.name}</a></h4>
                                                <p>{val.degi}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Team;