import { Component } from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


const title = "Welcome to the Herox";
const desc = "Entering The Herox Society means joining an family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";
const btnText = "View On opensea";


let bannerSlider = [
    {
        imgUrl: 'assets/images/nft/01.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/nft/02.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/nft/03.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/nft/04.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/nft/05.png',
        imgAlt: 'NFT Item Image',
    },
]



class Banner extends Component {
    render() { 
        return (
            <section id="home" className="banner" style={{backgroundImage: "url(/assets/images/banner/banner-bg.jpg)"}}>
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="banner__content" data-aos="fade-right" data-aos-duration="2000">
                                    <h1>{title}</h1>
                                    <p>{desc}</p>
                                    <a href="#" className="default-btn">{btnText}</a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner__thumb" data-aos="fade-left" data-aos-duration="2000">
                                    <div className="swiper banner__thumb-slider">
                                        <Swiper
                                            loop={'true'}
                                            effect={"cube"}
                                            grabCursor={true}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Autoplay]}
                                        >
                                            {bannerSlider.map((val, i) => (
                                                <SwiperSlide key={i}>
                                                    <div className="banner__item">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Banner;