import { Component } from "react";


import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const title = "Art X Collectibles";
const desc = "Entering The Herox Society means joining an family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";

let gallerySlider = [
    {
        imgUrl: 'assets/images/gallery/01.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/gallery/02.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/gallery/03.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/gallery/04.png',
        imgAlt: 'NFT Item Image',
    },
    {
        imgUrl: 'assets/images/gallery/05.png',
        imgAlt: 'NFT Item Image',
    },
]

class Gallery extends Component {
    render() { 
        return (
            <div className="gallery padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/gallery/bg.jpg)"}} data-aos="fade-up" data-aos-duration="1500">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="gallery__wrapper">
                        <div className="swiper gallery__slider">
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={2}
                                loop={'true'}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                breakpoints={{
                                    0: {
                                        width: 0,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        width: 768,
                                        slidesPerView: 3,
                                    },
                                    1200: {
                                        width: 1200,
                                        slidesPerView: 3.7,
                                    },
                                }}
                            >
                                {gallerySlider.map((val, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="gallery__item">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Gallery;