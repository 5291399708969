import { Component } from "react";

const title = "Growth Roadmap";
const desc = "Entering The Herox Society means joining an family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";


let roadmapList = [
    {
        title: 'Launch Initiated',
        parent: '20%',
        desc: 'Entering The Herox Society means joining family full people in who beleve in the future of Cryptourences and the Blockchain technology future of Cryptourences.',
    },
    {
        title: 'Physical Merch & Live Events',
        parent: '40%',
        desc: 'Entering The Herox Society means joining family full people in who beleve in the future of Cryptourences and the Blockchain technology future of Cryptourences.',
    },
    {
        title: 'The Heroxs',
        parent: '80%',
        desc: 'Entering The Herox Society means joining family full people in who beleve in the future of Cryptourences and the Blockchain technology future of Cryptourences.',
    },
    {
        title: 'Metaverse',
        parent: '100%',
        desc: 'Entering The Herox Society means joining family full people in who beleve in the future of Cryptourences and the Blockchain technology future of Cryptourences.',
    },
]


class Roadmap extends Component {
    render() { 
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="roadmap__wrapper">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="roadmap__content">
                                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                                        <h2>{title}</h2>
                                        <p>{desc}</p>
                                    </div>
                                    <div className="roadmap__timeline">
                                        <div className="roadmap__timeline-wrapper">
                                            {roadmapList.map((val, i) => (
                                                <div className="roadmap__timeline-item" data-aos="fade-up" data-aos-duration="500" key={i}>
                                                    <div className="roadmap__timeline-inner">
                                                        <div className="roadmap__timeline-title">
                                                            <h4>{val.title}</h4>
                                                            <p>{val.parent}</p>
                                                        </div>
                                                        <div className="roadmap__timeline-text">
                                                            <p>{val.desc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Roadmap;